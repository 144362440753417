import React from 'react';
import Link from 'next/link';
import { GetStaticProps } from 'next/types';
import { ParsedUrlQuery } from 'querystring';
import DefaultLayout from '@/components/layout';
import { getNavigation } from '@/lib/sanity/queries';
import { Button } from '@fagmobler/ui';

const Custom404 = () => {
  return (
    <div className="text-center py-52">
      <h1 className="hd-8xl">404</h1>
      <p className="body-md mb-10">
        Beklager! Siden ser ikke ut til å eksistere.
      </p>
      <Link legacyBehavior href="/">
        <Button>Gå tilbake til forsiden</Button>
      </Link>
    </div>
  );
};

export const getStaticProps: GetStaticProps<
  any,
  ParsedUrlQuery,
  { id: string }
> = async () => {
  const navigation = await getNavigation();
  return {
    props: {
      navigation,
    },
    revalidate: 60 * 60,
  };
};

export default Custom404;
